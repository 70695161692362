import './styles.scss';

document.addEventListener('DOMContentLoaded', function () {
  document.querySelectorAll('.anw-custom-scrollbar > ul').forEach(function (element) {
    const parent = element.parentElement;
    if (element.getBoundingClientRect().height <= parent.getBoundingClientRect().height) {
      parent.classList.remove('pe-2');
    }
  });
});
